<script setup>
import { computed } from 'vue';
import ConfigurationRadioSelection from './ConfigurationRadioSelection';
import { useConfiguratorApi } from '../composables/configurator';
import { useMainStore } from '../store/index';
import InformationIcon from './InformationIcon';
import InformationModal from './InformationModal';
import PriceSuffix from './PriceSuffix';
import MarkupSegment from './MarkupSegment';

const store = useMainStore();
const emit = defineEmits(['update:modelValue', 'select']);
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  item: {
    type: Object,
    required: true,
  },
  showDescription: {
    type: Boolean,
    default: false,
  },
  showSubOptions: {
    type: Boolean,
    default: true,
  },
  showPrice: {
    type: Boolean,
    default: true,
  },
  disabledSelections: {
    type: Array,
    default: () => [],
  },
  showInfo: {
    type: Boolean,
    default: false,
  },
  infoMessage: {
    type: String,
    default: '',
  },
  offerText: {
    type: String,
    default: null,
  },
});

const staticImagesBaseUrl = process.env.VUE_APP_DS_STATIC_IMAGES_BASE_URL;

const { getPrice, totalFormattedPrice } = useConfiguratorApi();

const packageOptions = computed(() => {
  return props.item?.options || [];
});

const modalId = computed(() => {
  return `${props.item.id}-modal`;
});

const price = computed(() => {
  return props.showSubOptions ? null : getPrice(packageOptions.value[0]);
});

const isMonthlyCost = computed(() => {
  if (props.modelValue.priceType === 'monthlyCost') return true;
  return false;
});

const absPrice = computed(() => {
  return Math.abs(price.value);
});

const numberSign = computed(() => {
  if (price.value < 0) return '-';
  return '+';
});

</script>

<template>
  <div
    class="package-selection"
    :class="{ selected: !showSubOptions }"
  >
    <div class="package-selection-header">
      <div class="package-selection-header__title">
        <h3>
          {{ $i18n(props.item.title) }}
          <span
            v-if="props.offerText"
            class="offerText"
          >
            {{ $i18n(props.offerText) }}
          </span>
        </h3>
      </div>
      <div
        v-if="!showSubOptions"
        class="package-selection-header__status-indicator package-selection-header__status-indicator--selected"
      >
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.744 8.48a1 1 0 0 1 .004 1.413 2709.096 2709.096 0 0 1-9.899 9.909 1642.18 1642.18 0 0 0-4.806 4.805 1 1 0 0 1-1.416.001l-7.355-7.362a1 1 0 0 1 1.415-1.414l6.646 6.653a2024.31 2024.31 0 0 1 5.35-5.342c2.362-2.356 5.155-5.146 8.647-8.66a1 1 0 0 1 1.414-.004Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    <div class="package-selection-content">
      <div
        v-if="!showSubOptions && showPrice"
        class="package-selection-cost"
      >
        <span class="package-selection-cost__price">>{{ numberSign }} {{ absPrice.toLocaleString('en').replace(/,/g,' ') }}</span>&nbsp;<PriceSuffix :price-suffix="store.selectedPaymentType === 'cash' && !isMonthlyCost ? totalFormattedPrice.priceSuffixOneTime : totalFormattedPrice.priceSuffixMonthly" />
      </div>
      <div class="package-selection-content__description">
        <img
          v-if="props.item.descriptionImage"
          :src="`${staticImagesBaseUrl}/${props.item.descriptionImage}`"
        >
        <MarkupSegment
          v-if="props.item.description"
          :text="$i18n(props.item.description)"
        />
      </div>
      <ConfigurationRadioSelection
        v-if="showSubOptions && packageOptions"
        :model-value="props.modelValue"
        :type="item.id"
        :options="packageOptions"
        :show-description="showDescription"
        :show-information="true"
        :is-inside-package="true"
        :disabled-selections="disabledSelections"
        @update:model-value="emit('update:modelValue', $event)"
        @select="emit('select', $event)"
      />
    </div>
    <div
      v-if="props.showInfo"
      class="driver-coaching-banner sdds-banner sdds-banner-info"
    >
      <div class="sdds-banner-body">
        <div class="sdds-banner-subheader">
          {{ props.infoMessage }}
        </div>
      </div>
    </div>
    <div
      v-if="props.item.information"
      :id="modalId"
      :data-analytics="`{'eventComponent':'configuration','eventType':'modal','eventLabel':'More information - ${$i18n(props.item.title, [], 'en-GB')} - ${props.item.id}'}`"
      class="package-selection-footer"
      :data-dd-action-name="`More information - ${$i18n(props.item.title, [], 'en-GB')} - ${props.item.id}`"
      @click.stop
    >
      <a
        href="#"
        @click.prevent
      >{{ $i18n('moreInformationText') }}</a>
      <InformationIcon />
    </div>
  </div>
  <InformationModal
    v-if="props.item.information"
    :id="modalId"
    :title="props.item.title"
    :information-text="props.item.information"
    :information-image="props.item.informationImage"
  />
</template>

<style scoped>
  h3 { margin: 0; }

  .package-selection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 70em;
    border: 1px solid var(--sdds-grey-300);
    border-radius: 1rem;
    background-color: var(--sdds-white);
  }

  .package-selection.selected {
    border-color: var(--sdds-blue);
  }

  .package-selection-content {
    padding: var(--sdds-spacing-element-16) var(--sdds-spacing-element-16);
  }
    .package-selection-content__description {
      display: flex;
      flex-direction: row;
    }
  .package-selection-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3em;
    border-top: 1px solid var(--sdds-grey-300);
    padding: var(--sdds-spacing-element-8) var(--sdds-spacing-element-16);
    background-color: var(--sdds-blue-50);
    cursor: pointer;
  }
    .package-selection-footer > a {
      line-height: 2em;
      border: 0;
      text-decoration: underline;
    }
  .package-selection-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--sdds-spacing-element-16) var(--sdds-spacing-element-16);
  }

    .package-selection-header__status-indicator {
      flex: 0 0 1.8em;
      height: 1.8em;
      border: 1px solid var(--sdds-grey-300);
      border-radius: 50%;
      color: var(--sdds-white);
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2.6px;
      padding: 0.2em;
      margin-left: 1em;
      -webkit-transition: background-color 0.1s ease-out;
      -moz-transition: background-color 0.1s ease-out;
      -ms-transition: background-color 0.1s ease-out;
      -o-transition: background-color 0.1s ease-out;
      transition: background-color 0.1s ease-out;
    }
    .package-selection-header__status-indicator--selected {
      background-color: var(--sdds-blue);
    }
    .package-selection-header__status-indicator {
      flex: 0 0 1.8em;
      height: 1.8em;
      border: 1px solid var(--sdds-grey-300);
      border-radius: 50%;
      color: var(--sdds-white);
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2.6px;
      padding: 0.2em;
      margin-left: 1em;
      cursor: pointer;
    }
      .package-selection-header__status-indicator--checkbox {
        border-radius: 0;
      }
      .package-selection-header__status-indicator--selected {
        background-color: var(--sdds-blue);
      }
    .package-selection-cost {
      font-size: 2em;
      vertical-align: top;
      font-family: "Scania Sans Regular", Arial, Helvetica, sans-serif;
      color: var(--sdds-grey-600);
    }
      .package-selection-cost__price {
        color: var(--sdds-grey-500);
      }
      .package-selection-cost__price--included {
        font-size: 0.7em;
        color: var(--sdds-grey-500);
      }
    .priceSuffix__container {
      font-size: 0.4em;
      line-height: 1em;
    }
  
  .offerText {
    background: #D95610;
    padding: 5px 15px;
    border-radius: 25px;
    color: white;
    font-size: 16px;
    margin-left: 25px;
  }

  @media only screen and (max-width: 1294px) {
      .package-selection-content__description > img {
        display: none;
      }
      .package-selection {
        max-width:  90vw;
      }
  }
</style>
