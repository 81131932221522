<script setup>
import { computed } from 'vue';
import { useConfiguratorApi } from '../composables/configurator';
import { useMainStore } from '../store/index.js';
import InformationModal from './InformationModal';
import InformationIcon from './InformationIcon';
import PriceSuffix from './PriceSuffix';
const store = useMainStore();

const emit = defineEmits(['click']);
const props = defineProps({
  selected: Boolean,
  checkbox: {
    type: Boolean,
    default: false,
  },
  option: {
    type: Object,
    required: true,
  },
  showDescription: {
    type: Boolean,
    default: true,
  },
  showInformation: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  packageLayout: {
    type: Boolean,
    default: false,
  },
  offerText: {
    type: String,
    default: null,
  },
});

const { getPrice, totalFormattedPrice } = useConfiguratorApi();

const staticImagesBaseUrl = process.env.VUE_APP_DS_STATIC_IMAGES_BASE_URL;

const price = computed(() => {
  return getPrice(props.option);
});

const modalId = computed(() => {
  return `modal-${props.option.id.replace(/\s/g, '').replace('+', '-').replaceAll('#', '_')}`;
});

const handleClick = () => {
  if (!props.disabled) emit('click');
};

const isMonthlyCost = computed(() => {
  if (props.option.priceType === 'monthlyCost') return true;
  return false;
});

const absPrice = computed(() => {
  return Math.abs(price.value);
});

const numberSign = computed(() => {
  if (price.value < 0) return '-';
  return '+';
});

</script>

<template>
  <div
    class="select-option select-option-full-width"
    :class="{ selected: props.selected && !props.disabled, disabled: props.disabled, 'package': packageLayout }"
    @click="handleClick()"
  >
    <div class="select-option-content">
      <div class="select-option-header">
        <div class="select-option-header__title">
          <h3>
            {{ $i18n(props.option.value) }}
            <span
              v-if="props.offerText"
              class="offerText"
            >
              {{ $i18n(props.offerText) }}
            </span>
          </h3>
        </div>
        <div
          v-if="packageLayout"
          class="select-option-cost"
        >
          <span class="select-option-cost__price">{{ numberSign }} {{ absPrice.toLocaleString('en').replace(/,/g,' ') }}</span>&nbsp;<PriceSuffix :price-suffix="store.selectedPaymentType === 'cash' && !isMonthlyCost ? totalFormattedPrice.priceSuffixOneTime : totalFormattedPrice.priceSuffixMonthly" />
        </div>
        <div
          class="select-option-header__status-indicator"
          :class="{ 'select-option-header__status-indicator--selected': props.selected, 'select-option-header__status-indicator--checkbox': props.checkbox }"
        >
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.744 8.48a1 1 0 0 1 .004 1.413 2709.096 2709.096 0 0 1-9.899 9.909 1642.18 1642.18 0 0 0-4.806 4.805 1 1 0 0 1-1.416.001l-7.355-7.362a1 1 0 0 1 1.415-1.414l6.646 6.653a2024.31 2024.31 0 0 1 5.35-5.342c2.362-2.356 5.155-5.146 8.647-8.66a1 1 0 0 1 1.414-.004Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <div
        v-if="!packageLayout"
        class="select-option-cost select-option-cost--content"
      >
        <span class="select-option-cost__price">{{ numberSign }} {{ absPrice.toLocaleString('en').replace(/,/g,' ') }}</span>&nbsp;<PriceSuffix :price-suffix="store.selectedPaymentType === 'cash' && !isMonthlyCost ? totalFormattedPrice.priceSuffixOneTime : totalFormattedPrice.priceSuffixMonthly" />
      </div>
      <div
        v-if="props.showDescription"
        class="select-option-description"
      >
        <div
          v-if="props.option.descriptionImage"
          class="select-option-description__image"
        >
          <img
            :src="`${staticImagesBaseUrl}/${props.option.descriptionImage}`"
            alt="Description image"
          >
        </div>
        <div
          v-if="props.option.description"
          class="select-option-description__text"
        >
          <p>
            {{ $i18n(props.option.description) }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="props.showInformation && (props.option.information || props.option.informationImage)"
      :id="modalId"
      :data-analytics="`{'eventComponent':'configuration','eventType':'modal','eventLabel':'More information - ${$i18n(props.option.value, [], 'en-GB')} - ${props.option.id}'}`"
      class="select-option-footer"
      @click.stop
    >
      <a
        :href="`#${modalId}`"
        data-dd-action-name="more information"
      >{{ $i18n('moreInformationText') }}</a>
      <InformationIcon />
    </div>
  </div>
  <InformationModal
    v-if="props.showInformation && (props.option.information || props.option.informationImage)"
    :id="modalId"
    :title="$i18n(props.option.value)"
    :information-text="$i18n(props.option.information)"
    :information-image="props.option.informationImage"
  />
</template>

<style scoped>
  h3 { margin: 0 }
  p { margin: 0 }

  .select-option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 1 30em;
    min-width: 15em;
    border: 2px solid;
    border-color: var(--sdds-grey-50);
    border-radius: 1rem;
    cursor: pointer;
    background-color: var(--sdds-white);
    -webkit-transition: border-color 0.2s ease-out;
    -moz-transition: border-color 0.2s ease-out;
    -ms-transition: border-color 0.2s ease-out;
    -o-transition: border-color 0.2s ease-out;
    transition: border-color 0.2s ease-out;
  }
  .select-option.select-option-full-width {
    flex-basis: 100%;
  }
    .configurator-section:nth-child(even) .select-option { border-color: var(--sdds-grey-100); }
    .select-option.package { max-width: 40em; }
    .select-option:hover:not(.selected) {
      border-color: var(--sdds-grey-300);
    }
    .select-option.selected, .configurator-section:nth-child(even) .select-option.selected  {
      border-color: var(--sdds-blue);
    }
  .select-option-content {
    padding: var(--sdds-spacing-element-16) var(--sdds-spacing-element-16);
  }
  .select-option-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3em;
    border-top: 1px solid var(--sdds-grey-300);
    padding: var(--sdds-spacing-element-8) var(--sdds-spacing-element-16);
    background-color: var(--sdds-blue-50);
    cursor: pointer;
  }
    .select-option-footer > a {
      line-height: 2em;
      border: 0;
      text-decoration: underline;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  .select-option-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: var(--sdds-spacing-element-16);
    align-items: flex-start;
  }
    .select-option-header__title {
      flex-grow: 2;
    }
    .select-option-header__status-indicator {
      flex: 0 0 1.8em;
      height: 1.8em;
      border: 1px solid var(--sdds-grey-300);
      border-radius: 50%;
      color: var(--sdds-white);
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2.6px;
      padding: 0.2em;
      margin-left: 1em;
      -webkit-transition: background-color 0.1s ease-out;
      -moz-transition: background-color 0.1s ease-out;
      -ms-transition: background-color 0.1s ease-out;
      -o-transition: background-color 0.1s ease-out;
      transition: background-color 0.1s ease-out;
    }
      .select-option-header__status-indicator--checkbox {
        border-radius: 0;
      }
      .select-option-header__status-indicator--selected {
        background-color: var(--sdds-blue);
      }
  .select-option-description {
    display: flex;
    flex-direction: row;
    gap: var(--sdds-spacing-element-24);
  }
  .select-option-description__image {
    flex: 0 0 10em;
  }
    .select-option-description__image > img {
      max-width: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -ms-transform: translateZ(0);
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      transform: translateZ(0);
    }
  .select-option-cost {
    font-size: 2em;
    vertical-align: top;
    font-family: "Scania Sans Regular", Arial, Helvetica, sans-serif;
    color: var(--sdds-grey-600);
  }
    .select-option-cost--content { margin-bottom: var(--sdds-spacing-element-16); }
    .select-option-cost__price {
      color: var(--sdds-grey-500);
    }
    .priceSuffix__container {
      font-size: 0.4em;
      line-height: 1em;
    }
    .select-option-cost__price--included {
      font-size: 0.7em;
      color: var(--sdds-grey-500);
    }

  /* Disabled */
  .select-option.disabled {
    cursor: auto;
    color: var(--sdds-grey-300);
  }
  .disabled .select-option-cost__price--included { color: var(--sdds-grey-300); }
  .select-option:hover.disabled { border-color: var(--sdds-grey-300) }

  .offerText {
    background: #D95610;
    padding: 5px 15px;
    border-radius: 25px;
    color: white;
    font-size: 16px;
    margin-left: 25px;
  }

  @media only screen and (max-width: 1000px) {
    .select-option {
      flex-shrink: 0;
      min-width: 300px;
      scroll-snap-align: center;
    }
    .select-option.package {
      max-width: 90vw;
    }
    .select-option-description {
      flex-direction: column-reverse;
    }
    .select-option-description__image {
      display: flex;
      place-content: center;
    }
    .select-option-description__image > img {
      max-width: 50%;
    }
  }

  @media only screen and (max-width: 500px) {
    .select-option {
      max-width: 80vw;
    }
    .select-option.select-option-full-width {
      max-width: 100vw;
      min-width: auto;
    }
    .package > .select-option {
      min-width: 60vw;
      max-width: 60vw;
    }
  }

</style>
